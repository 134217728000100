#dropdown-button {
  padding: 0;
  border: none;
  background: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #e8e8e8;
  font-weight: bold;
  cursor: pointer;
  font-size: 17px;
  padding: 0 1rem;
  padding-bottom: 2px;
  align-items: center;
}

#dropdown-button:hover {
  color: #a9a9a9;
}

#dropdown-button:active {
  color: #101010;
}

#dropdown-content {
  padding: 0;
  border: none;
  background: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #a9a9a9;
  font-weight: bold;
  cursor: pointer;
  font-size: 17px;
  padding: 0 1rem;
  padding-bottom: 2px;
  align-items: center;
  text-decoration: "none";
}

#dropdown-content:hover {
  color: #101010;
}

#drawer-dropdown-button {
  padding: 0;
  border: none;
  background: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #787878;
  font-weight: bold;
  cursor: pointer;
  font-size: 17px;
  padding: 0 1rem;
  padding-bottom: 2px;
  align-items: center;
}

#drawer-dropdown-button:hover {
  color: #101010;
}

#drawer-dropdown-button:focus {
  color: #101010;
}

#drawer-dropdown-button:active {
  color: #101010;
}

#drawer-dropdown-content {
  padding: 0;
  border: none;
  background: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #787878;
  font-weight: bold;
  cursor: pointer;
  font-size: 17px;
  padding: 0 1rem;
  padding-bottom: 2px;
  align-items: center;
  text-decoration: "none";
}

#drawer-dropdown-content:hover {
  color: #101010;
}
