#react-paginate ul {
  display: inline-block;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  text-decoration: none;
  font-weight: bold;
}

#react-paginate li {
  display: inline-block;
  border: 2px solid rgb(0, 0, 0);
  cursor: pointer;
  /* background-color: white; */
  margin-right: 8px;
  border-radius: 3px;
}

.active-page {
  background: #007cc2;
  color: white;
}

.active-page :hover {
  background: #007cc2 !important;
  color: white !important;
}

.active-page a {
  cursor: default;
}

#react-paginate li :hover {
  background: #d1d1d1;
}

#react-paginate li a {
  padding: 3px 8px;
  display: inline-block;
  text-decoration: none;
  outline: none;
}

.previous a,
.next a {
  color: #101010;
}

.disabled {
  border: 2px solid #d1d1d1 !important;
}

.disabled :hover {
  background: none !important;
}

.disabled a {
  color: #d1d1d1;
  cursor: default;
}

/* #react-paginate li :active {
  background: #007cc2;
  color: white;
  outline: none;
} */

a:link {
  text-decoration: none;
}

a:hover {
  color: #101010;
}